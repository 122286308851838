import React from 'react';
import CardList from "../../components/CardList/CardList";
import imageTest from "../../assets/images/photo-test.jpg";

const cardList = [
    {
        image: imageTest,
        date: Date.now(),
        title: "Title1",
        details: "Details",
        onClick: () => console.log("coucou1"),
        key: 1
    },
    {
        image: imageTest,
        date: Date.now(),
        title: "Title2",
        details: "Details",
        onClick: () => console.log("coucou1"),
        key: 2
    },
    {
        image: imageTest,
        date: Date.now(),
        title: "Title3",
        details: "Details",
        onClick: () => console.log("coucou1"),
        key: 3
    },
    {
        image: imageTest,
        date: Date.now(),
        title: "Title4",
        details: "Details",
        onClick: () => console.log("coucou1"),
        key: 4
    },

];

function SocialActions() {
    return (
        <div>
            <CardList cardList={cardList}/>
        </div>
    );
}

export default SocialActions;
