import React from "react";

function Button({children, onClick}) {
    const arrowLeft = <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"/>
    </svg>


    return (
        <div className="flex flex-row
      cursor-pointer
      leading-3.5 bg-transparent capitalize border border-[#6B7280]
      hover:border-secondary-dark
      text-xs text-[#6B7280]
      hover:text-secondary-dark
      font-bold py-2 px-4 rounded-full"
             onClick={onClick}>
            {children}{arrowLeft}
        </div>
    );
}

export default Button;
